import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/login");
  }
};

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../views/Home.vue"),
  // },
  {
    path: "/chat/:id/:convertion_id",
    name: "Chat",
    props: true,
    component: () => import("../views/Chat.vue"),
  },
  {
    path: "*",
    name: "n404NotFound",
    component: () => import("../views/n404NotFound.vue"),
    // redirect: {
    //   name: "Home",
    // },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

export default router;
