import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueSocketIO from "vue-socket.io";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VueRouter from "vue-router";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

if (window.Vue) {
  window.Vue.use(VueRouter);
}

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(require("vue-moment"));
Vue.use(Datetime);
Vue.use(CoolLightBox);
Vue.component("datetime", Datetime);

Vue.use(
  new VueSocketIO({
    //connection: "wss://chat.gowawe.com",
    connection: "wss://chat.gowawe.com",
    options: {
      transports: ["polling"],
    },
  })
);

Vue.config.productionTip = false;

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 422) {
//       store.commit("setErrors", error.response.data.errors);
//     } else if (error.response.status === 401) {
//       store.commit("auth/setUserData", null);
//       localStorage.removeItem("authToken");
//       router.push({ name: "Login" });
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

// axios.interceptors.request.use(function(config) {
//   config.headers.common = {
//     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//     "Content-Type": "application/json",
//     Accept: "application/json"
//   };

//   return config;
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
