<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: null,
    };
  },
};
</script>
